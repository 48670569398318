<template>
   <b-card
	   class="card-statistics"
	   no-body
   >
	  <b-card-header>
		 <b-card-title>Γενική εικόνα έτους</b-card-title>
		 <b-card-text class="mr-25 mb-0">
			Οι τιμές εμφανίζονται σε EURO
		 </b-card-text>
	  </b-card-header>
	  <b-card-body class="statistics-body">
		 <b-row>
			<b-col
				v-for="item in statisticsItems"
				:key="item.icon"
				:class="item.customClass"
				class="mb-2 mb-md-0"
				cols="6"
				lg="3"
			>
			   <b-media no-body>
				  <b-media-aside

					  class="mr-1"
				  >
					 <b-avatar
						 :variant="item.color"
						 rounded
						 size="48"
					 >
						<feather-icon
							:icon="item.icon"
							size="24"
						/>
					 </b-avatar>
				  </b-media-aside>
				  <b-media-body class="my-auto">
					 <h4 class="font-weight-bolder mb-0">
						{{ item.title }}
					 </h4>
					 <b-card-text class="font-small-3 mb-0">
						{{ item.subtitle }}
					 </b-card-text>
				  </b-media-body>
			   </b-media>
			</b-col>
		 </b-row>
	  </b-card-body>
   </b-card>
</template>

<script>
import {
   BAvatar,
   BCard,
   BCardBody,
   BCardHeader,
   BCardText,
   BCardTitle,
   BCol,
   BMedia,
   BMediaAside,
   BMediaBody,
   BRow,
} from 'bootstrap-vue'
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import {mapGetters} from 'vuex'
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardHeader,
	  BCardTitle,
	  BCardText,
	  BCardBody,
	  BMedia,
	  BAvatar,
	  BMediaAside,
	  BMediaBody,
   },
   mixins: [CurrencyMixin],
   data() {
	  return {
		 aggregations: [],
		 statisticsItems: [],
		 isMinus: false
	  }
   },
   computed: {
	  ...mapGetters(["getAggregations"]),
   },
   watch: {
	  getAggregations(n, o) {
		 this.statisticsItems = [
			{
			   icon: 'TrendingUpIcon',
			   color: 'light-primary',
			   title: this.convertFormatPrice(this.getAggregations.tzirosEtous),
			   subtitle: 'Έργα',
			   customClass: 'mb-2 mb-md-2 mb-lg-0',
			},
			{
			   icon: 'DollarSignIcon',
			   color: 'light-success',
			   title: this.convertFormatPrice(this.getAggregations.paymentsEtous),
			   subtitle: 'Εισπράξεις',
			   customClass: 'mb-2 mb-md-2 mb-lg-0',
			},
			{
			   icon: this.getAggregations.ofilesEtous * -1 > 0 ? 'SmileIcon' : 'FrownIcon',
			   color: this.getAggregations.ofilesEtous * -1 > 0 ? 'light-success' : 'light-danger',
			   title: this.convertFormatPrice(this.abs(this.getAggregations.ofilesEtous * -1)),
			   subtitle: this.getAggregations.ofilesEtous * -1 > 0 ? 'Πλεόνασμα' : 'Ωφειλές',
			   customClass: 'mb-2 mb-md-2 mb-lg-0',
			},
		 ]

	  },
   },
   methods: {
	  abs(value) {

		 return caldoSettings.abs(value);
	  },
	  makeAbs(value) {


		 if (value >= 0) {
			this.isMinus = true;
		 } else {
			this.statisticsItems.icon = 'SmileIcon'
			this.isMinus = false;
		 }
		 return value
	  }
   }


}
</script>

<style scoped>
.card-statistics .card-header {
   padding: 1.5rem !important;
}

.card-statistics .statistics-body {
   padding: 2rem 1.5rem 0 !important;
}
</style>
