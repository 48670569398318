<template>
   <!-- start::outer div -->
   <div>
	  <b-row class="match-height">
		 <b-col md="4">
			<stats-earnings :aggregations="aggregations"></stats-earnings>
		 </b-col>
		 <b-col md="8">
			<stats-overall :aggregations="aggregations"></stats-overall>
		 </b-col>
	  </b-row>

	  <b-card class="mb-0"
			  no-body>
		 <div class="m-2">
			<b-row>
			   <b-col cols="6">
				  <div class="d-flex align-items-center">
					 <!-- start::column per page -->
					 <template v-if="perPageOptions.length >= 1">
						<b-row class="mr-0">
						   <b-col
							   class="
                      d-flex
                      align-items-center
                      justify-content-start
                      mb-1 mb-md-0
                    "
							   cols="12"
						   >
							  <v-select
								  v-model="filters.selectedPageOptions"
								  :clearable="false"
								  :options="perPageOptions"
								  class="per-page-selector d-inline-block mx-50"
								  @input="pageOptionChanged"
							  >
								 <template v-slot:no-options>{{
									   $t("errors.no-matching")
															 }}
								 </template>
							  </v-select>
						   </b-col>
						</b-row>
					 </template>
					 <!-- end::column per page -->
					 <!--					 <table-filters @selectedCategory="(e)=>this.filters.categoryId = e.id"-->
					 <table-filters
						 :categories="allProject"
						 @selectedBalance="onBalance"
						 @selectedCategory="onFilter"
						 @selectedStatus="onStatus"
					 ></table-filters>

					 <default-filter :order="order" :sort="sort" defaultFilter="Ημερομηνία" @default="defaultFilter">

					 </default-filter>
				  </div>
			   </b-col>

			   <!-- start::column search -->
			   <b-col cols="12"
					  md="6">
				  <div class="d-flex align-items-center justify-content-end">
					 <b-form-input
						 v-model="filters.term"
						 aria-placeholder="Search..."
						 class="d-inline-block mr-1"
					 />
					 <b-button
						 v-b-toggle.sidebar-project
						 class="text-nowrap"
						 variant="primary"
						 @click.prevent="drawerActive = true"
					 >
						<feather-icon class="mr-25"
									  icon="PlusIcon" />
						<span>{{ $t("generic.add") }}</span>
					 </b-button>
				  </div>
			   </b-col>
			   <!-- end::column search -->

			   <!--			   <b-button-->
			   <!--				   class="ml-2"-->
			   <!--				   id="table-filters"-->
			   <!--				   ref="dropdown"-->
			   <!--				   @click="resetFilter"-->
			   <!--				   left-->
			   <!--				   variant="outline-secondary"-->
			   <!--				   text="Φίλτρα"-->
			   <!--			   >-->
			   <!--				  Επαναφορά-->
			   <!--			   </b-button>-->
			</b-row>
		 </div>

		 <!-- Start: list table -->
		 <b-table
			 :key="key"
			 id="contacts-table"
			 :busy="isLoading"
			 :current-page="filters.currentPage"
			 :empty-text="$t('errors.no-entries-found')"
			 :fields="tableColumns"
			 :items="getProjects.data"
			 :perPage="0"
			 class="position-relative white-space"
			 no-local-sorting
			 primary-key="id"
			 responsive
			 show-empty
			 :sort-by="getOrderName(this.order)"
			 :sort-desc="this.sort==='desc'"
			 @sort-changed="sortingChanged"
		 >
			<template #empty="scope">
			   <div class="text-center py-10 my-3">
				  <feather-icon
					  class="text-primary op2"
					  icon="FolderPlusIcon"
					  size="70"
				  />
				  <h4 class="my-1 op2">{{ $t("generic.notfound-generic") }}</h4>
			   </div>
			</template>

			<template #table-busy>
			   <div class="text-center text-danger my-2">
				  <!--				  <b-spinner class="align-middle"></b-spinner>-->
				  <strong>{{ $t("generic.loading") }}</strong>
			   </div>
			</template>

			<template #cell(name)="data">
			   <a href="#">
				  <div>
					 <div class="font-weight-bold">
						{{ data.value.project }}
					 </div>
					 <div class="font-small-2 text-muted">
						{{ data.value.client }}
					 </div>
				  </div>
			   </a>
			</template>

			<template #cell(title)="data">
			   <b-link
				   :to="{ name: 'projects-view', params: { projectId: data.item.id } }"
				   class="mr-1 font-weight-bold d-block text-nowrap"
			   >
				  {{ truncate(data.item.title) }}
			   </b-link>
			   <small class="text-muted"
			   >{{ data.item.contact.surname }}
				{{ data.item.contact.name }}
			   </small>
			</template>

			<!-- Column: Tasks -->
			<template #cell(tasks)="data">
			   <div class="text-nowrap">
				  <feather-icon
					  :id="`invoice-row-${data.item.id}`"
					  class="mr-50 text-primary"
					  icon="LayersIcon"
					  size="18"
				  />
				  <span v-if="data.item.tasks"
						class="align-text-top">{{
						data.item.tasks.length
											   }}</span>
			   </div>
			   <b-tooltip :target="`invoice-row-${data.item.id}`"
						  placement="top">
				  <p v-if="data.item.tasks"
					 class="mb-0">
					 Ολοκληρωμένα:
					 {{
						data.item.tasks.filter((el) => el.status === "Ολοκληρωμένο")
							.length
					 }}
				  </p>
				  <p v-if="data.item.tasks"
					 class="mb-0">
					 Σε εξέλιξη:
					 {{
						data.item.tasks.filter((el) => el.status === "Σε εξέλιξη")
							.length
					 }}
				  </p>
			   </b-tooltip>
			</template>

			<!-- Column: Status -->
			<template #cell(status)="data">
			   <b-badge
				   v-if="data.item.status == 'Σε εξέλιξη'"
				   pill
				   variant="light-primary"
			   >
				  {{ data.item.status }}
			   </b-badge>

			   <b-badge
				   v-else-if="data.item.status == 'Σε αναμονή'"
				   pill
				   variant="light-warning"
			   >
				  {{ data.item.status }}
			   </b-badge>
			   <b-badge
				   v-else
				   :variant="
              data.item.status == 'Ολοκληρωμένο'
                ? 'light-success'
                : 'light-danger'
            "
				   pill
			   >
				  {{ data.item.status }}
			   </b-badge>
			</template>

			<!-- Column: Balance -->
			<template #cell(remainder)="data">
			   <template
				   v-if="data.item.remainder === 0 && data.item.payments.length"
			   >
				  Εξοφλημένο
			   </template>
			   <template
				   v-else-if="data.item.remainder < 0 && data.item.payments.length"
			   >
				  {{ data.item.remainder * -1 > 0 ? "€ +" : "€" }}{{
					 new Intl.NumberFormat("de-DE", {
						style: "currency",
						currency: "EUR",
						currencyDisplay: "code",
					 })
						 .format(Math.abs(data.item.remainder))
						 .replace("EUR", "")
						 .trim()
				  }}
			   </template>
			   <template
				   v-else-if="
              data.item.remainder === 0 && !data.item.payments.length
            "
			   >
				  {{
					 "€" +
					 new Intl.NumberFormat("de-DE", {
						style: "currency",
						currency: "EUR",
						currencyDisplay: "code",
					 })
						 .format(Math.abs(data.item.remainder))
						 .replace("EUR", "")
						 .trim()
				  }}
			   </template>
			   <template v-else>

				  {{ data.item.remainder * -1 > 0 ? "€ +" : "€" }}
				  {{
					 new Intl.NumberFormat("de-DE", {
						style: "currency",
						currency: "EUR",
						currencyDisplay: "code",
					 })
						 .format(Math.abs(data.item.remainder))
						 .replace("EUR", "")
						 .trim()
				  }}
			   </template>
			</template>

			<!-- Column: Pendigconst -->

			<!-- start::column actions -->
			<template #cell(actions)="data">
			   <div class="text-nowrap">
				  <b-tooltip
					  :target="`invoice-row-${data.item.id}-preview-icon`"
					  :title="$t('generic.view')"
				  />

				  <b-link
					  :to="{
                name: 'projects-view',
                params: { projectId: data.item.id },
              }"
					  class="mr-1"
				  >
					 <feather-icon icon="EditIcon" />
				  </b-link>

				  <b-link @click="deleteProject(data)">
					 <feather-icon icon="TrashIcon" />
				  </b-link>
			   </div>
			</template>
			<!-- end::column actions -->
		 </b-table>
		 <!-- End: list table -->

		 <div class="mx-2 mb-2">
			<b-row>
			   <b-col
				   class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
				   cols="12"
				   sm="6"
			   >
            <span class="text-muted">
              {{ $t("tables.view-page") }} {{ filters.currentPage }}
              {{ $t("tables.from") }}
              {{ Math.round(totalPages / filters.selectedPageOptions) }}
              {{ $t("tables.in-total") }} {{ totalPages }}
              {{ $t("tables.entries") }}.
            </span>
			   </b-col>

			   <b-col
				   class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
				   cols="12"
				   sm="6"
			   >
				  <b-pagination
					  v-model="filters.currentPage"
					  :per-page="filters.selectedPageOptions"
					  :total-rows="totalPages"
					  aria-controls="contacts-table"
					  class="mb-0 mt-1 mt-sm-0"
					  first-number
					  last-number
					  next-class="next-item"
					  prev-class="prev-item"
				  >
					 <template #prev-text>
						<feather-icon icon="ChevronLeftIcon"
									  size="18" />
					 </template>
					 <template #next-text>
						<feather-icon icon="ChevronRightIcon"
									  size="18" />
					 </template>
				  </b-pagination>
			   </b-col>
			</b-row>
		 </div>
	  </b-card>

	  <sidebar-project
		  id="sidebar-project"
		  :all-categories="$store.getters.getAllContacts"
		  :all-projects="allProject"
		  :form="form"
		  title="Νέο Έργο"
		  @submit="onSubmit"
	  />
   </div>
</template>

<script>
import {
   BAvatar,
   BBadge,
   BButton,
   BCard,
   BCol,
   BDropdown,
   BDropdownForm,
   BDropdownItem,
   BFormInput,
   BLink,
   BMedia,
   BPagination,
   BRow,
   BTable,
   BTooltip,
   VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import TableFilters from "./TableFilters.vue";
import StatsEarnings from "./Stats/StatsEarnings.vue";
import StatsOverall from "./Stats/StatsOverall.vue";
import _ from "lodash";
import {mapGetters} from "vuex";
import ToastMixin from "@/Mixins/ToastMixin";
import axiosIns from "@/libs/axios";
import SidebarProject from "@/views/caldo-global/SidebarProject";
import moment from "moment";
import FormMixin from "@/Mixins/FormMixin";
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import caldoSettings from "@/caldoSettings";
import DefaultFilter from "@/views/projects/projects-list/DefaultFilter";

export default {
   components: {
	  DefaultFilter,
	  BCard,
	  SidebarProject,
	  BRow,
	  BCol,
	  vSelect,
	  BFormInput,
	  BButton,
	  BBadge,
	  BTable,
	  BTooltip,
	  BMedia,
	  BAvatar,
	  BLink,
	  BDropdown,
	  BDropdownItem,
	  BPagination,
	  StatsEarnings,
	  StatsOverall,
	  TableFilters,
	  BDropdownForm,
   },
   directives: {
	  "b-toggle": VBToggle,
   },
   props: {test: String},
   computed: {
	  ...mapGetters(["getProjects", 'getDynamicPreference']),

	  async getAllCategories() {
		 const responseProject = await axiosIns.get(`/project-category`, {
			params: {
			   rpp: 100,
			},
		 });

		 return responseProject.data;
	  },

	  resolveUserRoleIcon() {
		 return (gender) => {
			if (gender === 1) return "SettingsIcon";
			if (gender === 2) return "DatabaseIcon";
			if (gender === 3) return "Edit2Icon";
			return "UserIcon";
		 };
	  },
   },
   mixins: [ToastMixin, FormMixin, CurrencyMixin],
   data() {
	  return {
		 drawerActive: false,
		 form: {
			title: null,
			contactId: null,
			categoryId: null,
			status: "Σε εξέλιξη",
			startDate: new Date().toISOString().split("T")[0],
			deadline: null,
		 },
		 filters: {
			currentPage: 1,
			selectedPageOptions: this.$store.getters.getDynamicPreference['projectListResultsPerPage'],
			categoryId: null,
			status: null,
			remaining: null,
		 },
		 key:0,
		 aggregations: [],
		 allProject: [],
		 allCategories: [],
		 perPageOptions: [5, 10, 25, 50, 100],
		 recordsPerPage: 10,
		 totalPages: 0,
		 isLoading: false,
		 order: "",
		 sort: "",
		 tableColumns: [
			{key: "title", label: "ΕΡΓΟ", sortable: true},
			{key: "projectCategory", label: "ΚΑΤΗΓΟΡΙΑ", sortable: true},
			{key: "tasks", label: "TASKS", sortable: true},
			{key: "status", label: "ΚΑΤΑΣΤΑΣΗ", sortable: true},
			{
			   key: "deadline",
			   label: "ΗΜ/ΝΙΑ ΛΗΞΗΣ",
			   sortable: true,
			   formatter: (val) => {
				  if (!val) {
					 return "-";
				  }
				  return moment(val).format("DD-MM-YYYY");
			   },
			},
			{key: "remainder", label: "ΥΠΟΛΟΙΠΟ", sortable: true},
			{key: "actions", label: ""},
		 ],
		 items: [
			{
			   name: {
				  project: "Κατασκευή ιστοσελίδας",
				  client: "Client Full name",
			   },
			   category: "Κατηγορία 1",
			   tasks: {total: "7", completed: "5", in_progress: "2"},
			   status: {status: "Ολοκληρωμένο", variant: "light-success"},
			   balance: 0,
			   end_date: "21 Ιουν 2021",
			},
			{
			   name: {project: "Φωτογράφιση γάμου", client: "Client Full name"},
			   category: "Κατηγορία 2",
			   tasks: {total: "22", completed: "23", in_progress: "2"},
			   status: {status: "Σε εξέλιξη", variant: "light-warning"},
			   balance: "1.500,00",
			   end_date: "21 Απρ 2021",
			},
		 ],
	  };
   },
   watch: {
	  filters: {
		 deep: true,
		 handler() {

			this.fetchPages(this);

		 },
	  },
	  "$store.getters.getProjects": function () {
		 this.totalPages = this.$store.getters.getProjects.total;
	  }

   },

   methods: {
	  async pageOptionChanged(value) {
		 this.isLoading = true;
		 await this.$store.dispatch("setDynamicPreferences", ['projectListResultsPerPage', value]);


		 this.selectedPageOptions = value
	  },
	  async onSubmit() {
		 try {
			await this.$store.dispatch(
				"storeProjectCategory",
				this.removeEmpty(this.form)
			);
			this.$root.$emit("bv::toggle::collapse", "sidebar-project");
			this.resetField(this.form);
			this.form = {
			   status: "Σε εξέλιξη",
			   startDate: new Date().toISOString().split("T")[0],
			};
			this.notify(`Δημιουργήθηκε`);
			await this.fetchPages(this);
		 } catch (e) {
		 }
	  },
	  truncate(name, surname = "") {
		 return caldoSettings.truncateString(`${name} ${surname}`);
	  },

	  fetchPages: _.debounce(async (self) => {
		 self.isLoading = true;
		 await self.$store.dispatch("fetchProjectCategories", self.filter());

		 self.totalPages = self.$store.getters.getProjects.total;
		 self.isLoading = false;
	  }, 100),
	  onFilter(e) {
		 this.filters.categoryId = e?.id ?? null;
	  },

	  onStatus(e) {
		 this.filters.status = e ?? null;
	  },
	  onBalance(e) {
		 this.filters.remaining = e ?? null;
	  },
	  async resetFilter() {
		 this.filters.status = null;
		 this.filters.categoryId = null;
		 await this.fetchPages(this);
	  },
	  selectedCategory(value) {
	  },
	  async deleteProject(payload) {
		 const {isConfirmed} = await this.swal();
		 if (isConfirmed) {
			await this.$store.dispatch("deleteProject", payload.item.id);

			await this.$store.dispatch("fetchProjectCategories", this.filter());

			this.notify("Διαγράφηκε", `danger`);
		 }
	  },

	  filter() {
		 return {
			params: {
			   page: this.filters.currentPage,
			   rpp: this.filters.selectedPageOptions,
			   categoryId: this.filters.categoryId,
			   status: this.filters.status,
			   remaining: this.filters.remaining,
			   searchKey: this.filters.term === "" ? null : this.filters.term,
			   orderBy: [this.order],
			   sortBy: this.sort,
			},
		 };
	  },
	  async sortingChanged(value) {
		 console.log(value);
		 let order = {
			title: "title",
			projectCategory: "category",
			tasks: "tasks",
			status: "status",
			deadline: "deadline",
			remainder: "remaining",
			id: "id",
		 };
		 this.order = order[value.sortBy];
		 this.sort = value.sortDesc ? "desc" : "asc";
		 await this.$store.dispatch("fetchProjectCategories", {
			params: {
			   page: value.currentPage,
			   rpp: this.filters.selectedPageOptions,
			   categoryId: this.filters.categoryId,
			   status: this.filters.status,
			   remaining: this.filters.balance,
			   searchKey: this.filters.term === "" ? null : this.filters.term,
			   orderBy: [this.order],
			   sortBy: this.sort,
			},
		 });


		 let sortBy;
		 let orderBy;


		 switch (value.sortBy) {
			case 'title':
			   sortBy = 'title'
			   break;
			case 'projectCategory':
			   sortBy = 'category'
			   break;
			case 'tasks':
			   sortBy = 'tasks'
			   break;
			case 'status':
			   sortBy = 'status'
			   break;
			case 'deadline':
			   sortBy = 'deadline'
			   break;
			case 'remainder':
			   sortBy = 'remaining'
			   break;
			case 'id':
			   sortBy = 'id'
			   break;
		 }

		 orderBy = value.sortDesc ? 'desc' : 'asc'


		 await this.$store.dispatch("setDynamicPreferences", ['projects_list_order', `${sortBy}_${orderBy}`]);

	  },
	  async defaultFilter(){

		 await this.$store.dispatch("setDynamicPreferences", ['projects_list_order', `id_desc`]);
		 this.order = 'id';
		 this.sort = "desc";
		 await this.$store.dispatch("fetchProjectCategories", {
			params: {
			   page:1,
			   rpp: this.filters.selectedPageOptions,
			   categoryId: this.filters.categoryId,
			   status: this.filters.status,
			   remaining: this.filters.balance,
			   searchKey: this.filters.term === "" ? null : this.filters.term,
			   orderBy: [this.order],
			   sortBy: this.sort,
			},
		 });
		 this.key += 1

	  },
	  async getOrder() {
		 await this.$store.dispatch('fetchDynamicPreferences')
		 const order = await this.$store.getters.getDynamicPreference['projects_list_order'].split("_")


		 this.order = order[0]
		 this.sort = order[1];
	  },
	  getOrderName(value) {

		 switch (value) {
			case 'title':
			   return 'title'
			case 'category':
			   return 'projectCategory'
			case 'tasks':
			   return 'tasks'
			case 'status':
			   return 'status'
			case 'deadline':
			   return 'deadline'
			case 'remaining':
			   return 'remainder'
			case 'id':
			   return 'id'
		 }

	  }
   },

   async mounted() {
	  await this.getOrder()
	  await this.fetchPages(this);

	  await this.$store.dispatch("fetchAllCategoriesProject", this.filter());
   },
   async created() {

	  await this.getOrder()
	  this.filters.selectedPageOptions = await this.$store.getters.getDynamicPreference['projectListResultsPerPage']


	  await this.$store.dispatch("getAggregations");
	  this.aggregations = await this.$store.getters.getAggregations;
	  const responseProject = await axiosIns.get(`/contact`, {
		 params: {
			rpp: 100,
			orderBy: ["surname"],
		 },
	  });

	  this.allCategories = responseProject.data.data;

	  const responseProjects = await axiosIns.get(`/project-category`, {
		 params: {
			rpp: 100,
		 },
	  });
	  this.allProject = responseProjects.data;
	  this.isLoading = false;
   },
};
</script>

<style lang="scss"
	   scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "@core/scss/vue/libs/vue-flatpicker.scss";


.media-aside {
   top: 4px;
   position: relative;
}


.py-10 {
   padding: 50px 10px;
}


.op2 {
   opacity: 0.6;
}


.white-space {
   white-space: nowrap
}
</style>