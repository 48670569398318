<template>
   <b-card
	   class="earnings-card"
   >
	  <b-row>
		 <b-col cols="6">
			<b-card-title class="mb-1">
			   {{ $t('generic.projects') }}
			</b-card-title>
			<div class="font-small-2">
			   Αυτό το μήνα
			</div>
			<h4 class="mb-1">
			   €{{ convertFormatPrice(aggregations.tzirosOfCurrentMonth) }}
			</h4>
			<b-card-text class="text-muted font-small-2">
			   <span class="font-weight-bolder">{{ aggregations.tzirosPercentFromLastMonth }}%</span><span> από τον προηγούμενο μήνα</span>
			</b-card-text>
		 </b-col>
		 <b-col cols="6">
			<!-- chart -->
			<vue-apex-charts
				:options="earningsChart.chartOptions"
				:series="earningsChart.series"
				height="120"
			/>
		 </b-col>
	  </b-row>
   </b-card>
</template>

<script>
import {BCard, BCardText, BCardTitle, BCol, BRow,} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'
import CurrencyMixin from "@/Mixins/CurrencyMixin";

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
   components: {
	  BCard,
	  BRow,
	  BCol,
	  BCardTitle,
	  BCardText,
	  VueApexCharts,
   },
   props: {
	  aggregations: [Array, Object]
   },
   mixins: [CurrencyMixin],

   watch: {
	  aggregations: {
		 deep: true,
		 handler() {

			const arr = [];

			Object.values(this.aggregations.tzirosPercentByCategory).forEach((el, idx) => {
			   this.earningsChart.series.push(el)
			});
			Object.keys(this.aggregations.tzirosPercentByCategory).forEach((el, idx) => {
			   this.earningsChart.chartOptions.labels.push(el)
			});


			// this.earningsChart.chartOptions.plotOptions.pie.donut.labels.total.label = this.earningsChart.chartOptions.labels[0];
			// this.earningsChart.series.push(Object.values(this.aggregations.tzirosPercentByCategory).join())
			// this.earningsChart.labels.push(Object.keys(this.aggregations.tzirosPercentByCategory).join())

		 }

	  },
   },

   data() {
	  return {
		 earningsChart: {
			series: [],
			chartOptions: {
			   chart: {
				  type: 'donut',
				  toolbar: {
					 show: false,
				  },
			   },
			   dataLabels: {
				  enabled: false,
			   },
			   legend: {show: false},
			   comparedResult: [2, -3, 8],
			   labels: [],
			   stroke: {width: 0},
			   colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
			   grid: {
				  padding: {
					 right: -20,
					 bottom: -8,
					 left: -20,
				  },
			   },
			   plotOptions: {
				  pie: {
					 donut: {
						labels: {
						   show: true,
						   name: {
							  offsetY: 15,
						   },
						   value: {
							  offsetY: -15,
							  formatter(val) {
								 // eslint-disable-next-line radix
								 return `${parseInt(val)}%`
							  },
						   },
						   total: {
							  show: true,
							  offsetY: 15,
							  label: '',
							  formatter(e) {
								 return Math.floor(e.config.series[0]) + '%'
							  },
						   },
						},
					 },
				  },
			   },
			   responsive: [
				  {
					 breakpoint: 1325,
					 options: {
						chart: {
						   height: 100,
						},
					 },
				  },
				  {
					 breakpoint: 1200,
					 options: {
						chart: {
						   height: 120,
						},
					 },
				  },
				  {
					 breakpoint: 1045,
					 options: {
						chart: {
						   height: 100,
						},
					 },
				  },
				  {
					 breakpoint: 992,
					 options: {
						chart: {
						   height: 120,
						},
					 },
				  },
			   ],
			},
		 },
	  }
   },
}
</script>
